<template>
  <div class="flex flex-col">
    <span>
      {{ model.email }}
    </span>
    <span class="text-xs" v-if="model.pending_email">
      <i>{{ $t("COMMON.EMAIL_CHANGE_PENDING") }}</i>
    </span>
  </div>
</template>

<script lang="ts">
import type { IUser } from "@/models/users";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    model: {
      type: Object as PropType<IUser>,
      required: true
    }
  }
});
</script>
